import { Button } from 'antd'
import cn from 'classnames'
import PaymentTypes from 'parts/types/PaymentTypes'
import useSubscriptionsStore from '../../zustand/store'
import { LeadsStatsStoreType } from '../../zustand/storeTypes'
import { useGetPayTariffPlan } from './fn/getPay'
import s from './CardBottom.module.scss'

type CardBottomProps = {
	// Данные тарифа
	tariffPlan: LeadsStatsStoreType.TariffPlan
}

function CardBottom(props: CardBottomProps) {
	const { tariffPlan } = props

	return (
		<div className={s.bottomWrapper}>
			<Price tariffPlan={tariffPlan} />
			<BottomButtons tariffPlan={tariffPlan} />
		</div>
	)
}

export default CardBottom

type PriceProps = {
	// Данные тарифа
	tariffPlan: LeadsStatsStoreType.TariffPlan
}

function Price(props: PriceProps) {
	const { tariffPlan } = props

	const paymentDurationValue = useSubscriptionsStore(
		(store) => store.paymentDurationValue
	)

	if (tariffPlan.isFreePlan) {
		const description = tariffPlan.isTrialPlan
			? tariffPlan.trialPeriod + ' дней'
			: 'Навсегда'

		return (
			<div className={s.priceWrapper}>
				<p className={cn(s.priceText, s.priceTextFree)}>Бесплатно</p>
				<p className={s.priceDescription}>{description}</p>
			</div>
		)
	}

	if (paymentDurationValue === 'month' && tariffPlan.prices.monthlyPrice) {
		return (
			<div className={s.priceWrapper}>
				<p className={cn(s.priceText, s.priceTextPaid)}>
					{tariffPlan.prices.monthlyPrice}
					{tariffPlan.prices.currency}
					<span className={s.priceSubtext}>/месяц</span>
				</p>
				<p className={s.priceDescription}>при ежемесячной оплате</p>
			</div>
		)
	}

	return (
		<div className={s.priceWrapper}>
			<p className={cn(s.priceText, s.priceTextPaid)}>
				{Math.round(tariffPlan.prices.annuallyPrice! / 12)}
				{tariffPlan.prices.currency}
				<span className={s.priceSubtext}>/месяц</span>
			</p>
			<p className={s.priceDescription}>при оплате за год</p>
		</div>
	)
}

type BottomButtonsProps = {
	// Данные тарифа
	tariffPlan: LeadsStatsStoreType.TariffPlan
}

function BottomButtons(props: BottomButtonsProps) {
	const { tariffPlan } = props
	const paymentDurationValue = useSubscriptionsStore(
		(store) => store.paymentDurationValue
	)

	const payTariffPlanByStripe = useGetPayTariffPlan(
		PaymentTypes.System.Stripe,
		tariffPlan,
		paymentDurationValue
	)
	const payTariffPlanByYooKassa = useGetPayTariffPlan(
		PaymentTypes.System.UKassa,
		tariffPlan,
		paymentDurationValue
	)

	// Если это выбранный план (текущий)
	if (tariffPlan.isCurrentPlan) {
		return (
			<div className={s.bottomButtonsCenter}>
				<p className={s.currentPlanText}>Ваш текущий план</p>
			</div>
		)
	}

	if (tariffPlan.isFreePlan) {
		return (
			<div className={s.bottomButtonsCenter}>
				<Button
					block
					onClick={() => {
						alert('Не реализовано')
					}}
				>
					Перейти
				</Button>
			</div>
		)
	}

	return (
		<div className={s.bottomButtons}>
			<Button block type="primary" onClick={payTariffPlanByStripe}>
				Оплатить
			</Button>
			<Button block onClick={payTariffPlanByYooKassa}>
				Оплатить в рублях
			</Button>
		</div>
	)
}
